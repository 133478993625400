<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="7"
      >
        <b-card>
          <title-name />
          <sub-title />
          <ordering />
          <text-status />
          <status-select />
          <route-select />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <image-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Carousel/elements/Title.vue'
import SubTitle from '@/views/Admin/Carousel/elements/SubTitle.vue'
import TextStatus from '@/views/Admin/Carousel/elements/TextStatus.vue'
import StatusSelect from '@/views/Admin/Carousel/elements/Status.vue'
import Ordering from '@/views/Admin/Carousel/elements/Ordering.vue'
import RouteSelect from '@/views/Admin/Carousel/elements/RouteSelect.vue'
import ImageCard from '@/views/Admin/Carousel/elements/ImageCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    TitleName,
    SubTitle,
    TextStatus,
    StatusSelect,
    Ordering,
    RouteSelect,
    ImageCard,
  },
}
</script>
