<template>
  <b-row>
    <b-col cols="12">
      <b-form-group
        label="Ürün Bağlantısı"
        label-for="id_products"
      >
        <v-select
          id="id_products"
          v-model="dataItem.id_products"
          :options="productList"
          label="title"
          :reduce="item => item.id"
          placeholder="Ürün Bağlantısı"
          :disabled="dataItem.id_catalogs"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group
        label="Katalog Bağlantısı"
        label-for="id_catalogs"
      >
        <v-select
          id="id_catalogs"
          v-model="dataItem.id_catalogs"
          :options="catalogList"
          label="title"
          :reduce="item => item.id"
          placeholder="Katalog Bağlantısı"
          :disabled="dataItem.id_products"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RouteSelect',
  components: {
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['carousel/dataItem']
    },
    productList() {
      return this.$store.getters['carousel/productList']
    },
    catalogList() {
      return this.$store.getters['carousel/catalogList']
    },
  },
}
</script>
