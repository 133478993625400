<template>
  <b-form-group
    label="Başlık Durumu"
    label-for="text_status"
  >
    <validation-provider
      #default="{ errors }"
      name="Başlık Durumu"
      rules="required"
    >
      <v-select
        v-model="dataItem.text_status"
        :options="textStatuses"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'TextStatus',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      textStatuses: [
        {
          id: '1',
          title: 'Açık',
        },
        {
          id: '0',
          title: 'Kapalı',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['carousel/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
