<template>
  <div>
    <b-card title="Görsel">
      <b-img
        v-if="itemData.image"
        :src="baseURL + '/media/carousel/' + itemData.image"
        thumbnail
        center
      />
      <div class="text-center mt-1">
        <b-button
          variant="primary"
          @click="setModal"
        >
          <FeatherIcon icon="UploadIcon" />
          Görsel Yükle
        </b-button>
      </div>
    </b-card>
    <cropper-image
      v-if="cropperSettings.modalStatus"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      @uploadImage="setUploadImage"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BImg,
} from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'ImageCard',
  components: {
    CropperImage,
    BButton,
    BImg,
    BCard,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
  },
  methods: {
    setModal() {
      this.cropperSettings = {
        modalStatus: true,
        fileName: 'carousel-image.jpg',
        folder: 'carousel',
        ratio: 1080 / 1080,
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
      }
    },
    setUploadImage(item) {
      this.itemData.image = item.image
      this.cropperSettings.modalStatus = false
    },
  },
}
</script>
