<template>
  <b-form-group
    label="Alt Başlık"
    label-for="subtitle"
  >
    <b-form-input
      id="subtitle"
      v-model="dataItem.subtitle"
      placeholder="Alt Başlık"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'SubTitle',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['carousel/dataItem']
    },
  },
}
</script>
